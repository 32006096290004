.bein-modal-contribute {
    width: 360px !important;
    padding: $pd-2;
    text-align: center;
    top: 35%;
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        background-color: $neutral-80;
        border: 1px solid $neutral-50;
        box-shadow: $box-shadow-1;
        .heading-300 {
            color: $white-2;
        }
    }
    .modal-contribute-content {
        padding: 24px;
        position: relative;
    }
}
