.loading {
    position: fixed;
    z-index: 9999;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    width: 150px;
    height: 150px;
}
.loading-overlay {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255, 1);
}

.loading-icon{
    z-index: 9001;
    position: fixed;
    margin-top: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}