.btn-connect {
    background: $blue-40;
    border-radius: $border-radius-1 - 2;
    padding: $pd-0 + 4;
    text-align: center;
    border: none;
    width: 444px;
    margin: auto;
    color: $white-1 !important;
    transition: 0.3s;
    &:hover {
        background-color: $blue-30;
    }
}
.btn-connect-not-whitelist {
    background: $primary-50;
    &:hover {
        background-color: $primary-40 !important;
    }
}
.btn-submit {
    width: 100%;
    border: none;
    background-color: $neutral-40;
    padding: $pd-2 - 4;
    border-radius: $border-radius-1 - 2;
}
.btn-submit-on {
    background-color: $primary-50 !important;
    color: $neutral-1;
    &:hover {
        background-color: $primary-40 !important;
    }
}
.btn-add-calender {
    background-color: $primary-30 !important;
    width: 100%;
    color: $neutral-1;
    font-size: 13px;
    &:hover {
        background-color: $primary-10 !important;
    }
}
@media screen and (max-width: 480px) {
    .btn-connect {
        font-size: $fs-1 !important;
    }
}
