.bein-modal {
    top: 25%;
}
.bein-modal .ant-modal-header {
    border-bottom: none;
}
.bein-modal .ant-modal-content {
    max-width: 445px;
    height: auto;
    margin: auto;
    margin-top: 100px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    border: 1px solid #627286;
    background: #2f3945;
    border-radius: 6px;
}
.bein-modal .ant-modal-close-x {
    width: 16px;
    height: 16px;
    line-height: 0;
}
.bein-modal .ant-modal-close {
    top: 14px;
    right: 22px;
}
.bein-modal .ant-modal-body {
    padding: 40px 16px;
    padding-bottom: 16px;
}
.bein-modal .btn-modal-ok {
    background: #3f4b5b;
    border: 1px solid #627286;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    color: #D5DCE5;
    padding: 0;
}
.bein-modal .heading-400 {
    color: #e5616e;
    margin-bottom: 16px;
}
.heading-100 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #eaedf2;
    margin-bottom: 24px;
}
.bein-modal .ant-modal-close {
    color: #d5dce5;
}
