.bein-group {
    .ant-progress-text {
        display: none;
    }
    .ant-progress-outer {
        width: 100% !important;
        padding: 0;
    }
    .ant-progress-inner {
        border-radius: $border-radius-2;
        border: 1px solid $red-5;
        background-color: $neutral-80;
    }
    .ant-progress-status-exception .ant-progress-bg {
        height: 24px !important;
    }
    .ant-progress-status-exception .ant-progress-bg {
        background-color: $red-5;
        border-radius: 0px;
    }
    .bein-card-chart {
        .ant-radio-button-checked {
            background-color: $blue-50;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            font-weight: 600;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            box-shadow: none;
        }
    }
    .ant-table {
        background-color: $neutral-80;
        color: $neutral-1;
        border: 1px solid $neutral-50;
        ::-webkit-scrollbar {
            width: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background: #c4c4c4;
            border-radius: 4px;
        }
        ::-webkit-scrollbar:horizontal {
            height: 4px;
        }
    }
    .ant-table-thead > tr > th {
        background-color: $neutral-60;
        border-bottom: 1px solid $neutral-50;
        color: $white-1;
        border-top-right-radius: 0;
        border-top: 0;
        &::before {
            display: none;
        }
    }
    .ant-table-tbody > tr > td {
        border-bottom-color: $neutral-60;
    }
    .ant-table-pagination .ant-pagination {
        margin-bottom: 0;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background-color: $neutral-80;
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-color: $neutral-80;
    }
    .ant-empty-normal {
        color: $neutral-1;
    }
    td.ant-table-column-sort {
        background-color: $neutral-80;
    }
    .ant-table-cell-scrollbar {
        box-shadow: none;
    }
    .ant-table-summary {
        background-color: $neutral-70;
        box-shadow: none;
    }
    .ant-table-column-title {
        flex: none;
    }
    .ant-table-column-sorters {
        justify-content: flex-end;
    }
    .ant-table-cell:nth-child(2) {
        .ant-table-column-sorters {
            justify-content: flex-start;
        }
    }

    .ant-table-column-sorter {
        color: $neutral-1;
    }
    .ant-table table {
        border-radius: 0;
    }
    .ant-table-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    .ant-table-summary > tr > th,
    .ant-table-summary > tr > td {
        border-top: 1px solid $neutral-50;
        border-bottom: 0;
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background-color: $neutral-80;
    }
    .ant-table-column-sorters::after .ant-table-cell-fix-left-first::after,
    .ant-table-cell-fix-left-last::after {
        display: none;
    }
    .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
    .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
        background-color: $neutral-60;
    }
    .bein-table-sum {
        background-color: $neutral-70;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-color: $neutral-50;
    }
    .ant-table-column-sorters:hover .ant-table-column-sorter {
        color: $white-1;
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: $white-1;
    }
}
.ant-tooltip-inner {
    background-color: $neutral-70;
}
.ant-tooltip-arrow-content {
    background-color: $neutral-70;
}
.ant-popover-inner {
    background-color: $neutral-70;
    border-radius: 6px;
}
.ant-popover-message {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $neutral-5;
}
.ant-popover-buttons {
    display: flex;
    justify-content: center;
}
.ant-btn {
    background: $neutral-70;
    border: 1px solid $neutral-50;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 24px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: $white-1;
    &:hover {
        background-color: $neutral-60;
        border: 1px solid $neutral-50;
        color: $neutral-5;
    }
    span {
        display: block;
    }
}
.ant-btn-primary {
    background: $primary-50;
    border: none;
    transition: 0.3s;
    &:hover {
        border: none;
        background-color: $primary-40;
    }
}
.ant-popover-arrow-content {
    background-color: $neutral-70;
}
.anticon-exclamation-circle {
    display: none;
}
.ant-popover-message-title {
    padding: 0;
}
.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.65);
}
@media screen and (max-width: 720px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: $pd-2 - 4;
    }
    .ant-table {
        font-size: $fs-1 - 1;
    }
}

@media screen and (max-width: 576px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: $pd-0 + 1;
    }
    .ant-table {
        font-size: $fs-1 - 2;
    }
    .bein-icon-detail {
        width: 15px;
        height: 15px;
    }
}
@media screen and (max-width: 480px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: $pd-0;
    }
    .ant-table {
        font-size: $fs-1 - 2;
    }
    .bein-icon-detail {
        width: 15px;
        height: 15px;
    }
}
@media screen and (max-width: 410px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: $pd-0 - 2;
    }
    .ant-table {
        font-size: $fs-1 - 5;
    }
}
