@media screen and (min-width: 830px) and (max-width: 940px) {
    .bein-group-dark {
        .bein-card {
            .text-privateround {
                font-size: $fs-1 - 2;
            }
        }
    }
}
@media screen and (max-width: 892px) {
    .bein-group {
        .heading-100 {
            font-size: $fs-1 - 3;
        }
        .bein-card-rewards {
            .heading-100 {
                font-size: $fs-1;
            }
        }
        .bein-contract {
            .heading-100 {
                font-size: $fs-1;
            }
        }
        .content-price {
            font-size: $fs-1;
        }
        .bein-group-dark {
            .bein-card-rewards {
                .bein-group-ranking-top {
                    h3 {
                        font-size: $fs-1 + 2;
                    }
                    .heading-100 {
                        font-size: $fs-1 - 2;
                    }
                }
                .text-time-select {
                    font-size: $fs-1;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 894px) {
    .bein-form {
        .form-price-bot {
            .line {
                margin: 0 $mg-0 - 6;
            }
            .heading-200 {
                font-size: $fs-1 - 1;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 829px) {
    .bein-group-dark {
        .bein-card {
            .text-privateround {
                font-size: $fs-1 - 3;
            }
        }
        .bein-bic-title {
            font-size: $fs-1 + 1;
        }
        .bein-info {
            .heading-400 {
                font-size: 17px;
            }
            .content-price {
                font-size: 13px;
            }
            .text-privateround {
                font-size: 12px;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 920px) {
    .bein-group {
        .bein-group-dark {
            .form-price-top {
                .heading-200 {
                    font-size: 13px;
                }
            }
            .bein-card-right {
                .bein-price {
                    padding: 4px;
                    .content-balance-name {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 319px) and (max-width: 768px) {
    .bein-group {
        .bein-group-dark {
            .bein-card-right {
                .bein-price {
                    padding: 3px;
                    .content-balance-name {
                        font-size: 10px;
                    }
                    .heading-25 {
                        font-size: 8px;
                    }
                }
            }
        }
        .input-price {
            &::placeholder {
                font-size: 15px;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 786px) {
    .bein-group {
        .bein-group-dark {
            .bein-ranking {
                .bein-card-ranking {
                    .body-75 {
                        font-size: 11px;
                    }
                }
            }
            .bein-card-rewards {
                .bein-group-ranking-top {
                    h3 {
                        font-size: $fs-1 + 1;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .bein-group-dark {
        .bein-card {
            height: 400px;
        }
        .bein-rewards-details {
            .heading-100 {
                font-size: $fs-1 - 3;
            }
        }
    }
}
@media screen and (max-width: 767.98px) {
    .container {
        padding: 0 !important;
    }
    .row {
        margin: 0;
    }
    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-auto {
        padding: 0;
    }
    .bein-group-dark {
        .header {
            .header-introduce {
                h3 {
                    font-size: $fs-3;
                    line-height: 32px;
                    margin-bottom: $mg-0 - 4;
                }
            }
        }
        .bein-card {
            height: auto;
        }
        .divider {
            border: 0.5px solid $neutral-60;
        }
        .bein-contract {
            .bein-card-body {
                .heading-200 {
                    font-size: $fs-1;
                    line-height: 20px;
                }
            }
        }
        .input-price-bot {
            margin-bottom: 16px;
        }
        .bein-form {
            .form-floating-button {
                right: 20px;
            }
        }
        .bein-card-rewards {
            .bein-group-ranking-top {
                .bein-ranking-member-me {
                    padding: 0;
                }
            }
            .bein-card-body {
                padding: $pd-1;
            }
            .bein-hisotry-rewards-name {
                .heading-200 {
                    font-size: $fs-1;
                }
            }
            .bein-group-ranking-rewards {
                .bein-card-prize {
                    max-width: 200px;
                }
            }
        }
        .bein-card-ranking {
            .heading-100 {
                font-size: $fs-1;
            }
        }
        .bein-contract {
            // .infomation-wallet:first-child {
            //     margin-left: 0px;
            // }
            .infomation-wallet {
                margin-left: 16px;
            }
        }
    }
}
@media screen and (max-width: 682px) {
    .bein-group-dark {
        .bein-card-body {
            .bein-card-content {
                justify-content: flex-start;
                .infomation-wallet {
                    margin-right: $mg-0 + 4;
                }
            }
        }
        .bein-card-rewards {
            .bein-group-ranking-rewards {
                .bein-card-prize {
                    max-width: 160px;
                }
                .bein-rewards-body {
                    padding: 16px;
                    .bein-rewards-details {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 594px) {
    .bein-group-dark {
        .bein-contract {
            .bein-card-body {
                .bein-card-content {
                    display: inline-flex;
                    gap: 16px;
                    justify-content: flex-start;
                    .infomation-wallet {
                        margin-right: 0;
                    }
                }
            }
        }
        .bein-rewards-gift {
            width: 70px;
            height: 70px;
        }
    }
}
@media screen and (max-width: 575.98px) {
    .bein-group-dark {
        .header {
            .header-img {
                height: 95px;
            }
            .header-introduce {
                padding: $pd-1 0;
            }
        }
        .bein-info {
            .content-price {
                font-size: $fs-1;
            }
            .price-two {
                font-size: $fs-1;
                line-height: 20px;
            }
            .heading-400 {
                font-size: $fs-1 + 4;
                line-height: 20px;
            }
            .text-privateround {
                font-size: $fs-1 - 1;
            }
        }
        .bein-card-right {
            .heading-200 {
                font-size: $fs-1 - 2;
            }
        }
        .bein-form {
            .bein-card-button {
                .form-button {
                    width: 100%;
                }
            }
        }
        .bein-address {
            .row {
                margin: 0;
            }
        }
        .bein-address-title {
            padding-left: 0 !important;
        }
        .bein-card-rewards {
            .bein-group-ranking-time {
                .heading-100 {
                    font-size: $fs-1 - 4;
                }
            }
            .bein-group-ranking-rewards {
                .bein-card-prize {
                    max-width: 50%;
                }
                .bein-rewards-body {
                    padding: 16px;
                    .bein-rewards-details {
                        font-size: 20px;
                    }
                }
            }
        }
        .bein-rewards-gift {
            width: 99px;
            height: 74px;
        }
        .bein-card-rewards {
            .heading-100 {
                font-size: $fs-1;
            }
        }
        .bein-contract {
            .infomation-wallet:first-child {
                margin-left: 0px;
            }
            .infomation-wallet {
                margin-top: 12px;
            }
        }
    }
    .form-input-address {
        padding-right: 0;
    }
    footer {
        .heading-200 {
            font-size: $fs-1 !important;
        }
    }
}
@media screen and (max-width: 480px) {
    .bein-group-dark {
        .bein-card-chart {
            canvas {
                padding: 8px;
            }
        }
        .bein-card-rewards {
            .bein-group-ranking-time {
                .heading-100 {
                    font-size: $fs-1 - 1;
                }
            }
            .bein-card-body {
                padding: $pd-1;
            }
            .bein-rewards-body {
                justify-content: center !important;
                .heading-100 {
                    font-size: $fs-1;
                }
            }
        }
        .bein-ranking {
            .bein-card-ranking {
                .heading-100 {
                    font-size: 14px;
                }
            }
        }
    }
}
@media screen and (max-width: 440px) {
    .bein-group {
        .bein-group-dark {
            .bein-card-rewards {
                .bein-rewards-body {
                    padding: 16px;
                    .bein-rewards-details {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 420px) {
    .bein-group {
        .bein-group-dark {
            .bein-card-rewards {
                .bein-group-ranking-top {
                    h3 {
                        font-size: $fs-1;
                        line-height: 20px;
                    }
                }
                .btn-time-current {
                    padding: 8px 16px;
                }
                .bein-rewards-body {
                    padding: 16px;
                    .bein-rewards-details {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 380px) {
    .bein-group {
        .bein-group-dark {
            .bein-card-right {
                .content-input-price {
                    .address-input-whitelist {
                        font-size: $fs-1 - 5;
                    }
                }
            }
            .bein-form {
                .form-price-top {
                    .heading-400 {
                        font-size: 14px;
                    }
                    .btn-max-busd {
                        font-size: $fs-1 - 2;
                    }
                }
            }
            .bein-card-rewards {
                .bein-group-ranking-time {
                    .heading-100 {
                        font-size: $fs-1 - 3;
                    }
                    .heading-200 {
                        font-size: $fs-1 - 2;
                    }
                    .body-75 {
                        font-size: $fs-1 - 4;
                    }
                    .bein-hisotry-rewards-name {
                        .heading-200 {
                            font-size: $fs-1;
                        }
                    }
                }
                .bein-card-body {
                    padding: $pd-0 + 2;
                }
                .bein-group-ranking-top {
                    h3 {
                        margin-left: 8px;
                        font-size: $fs-1 - 1;
                    }
                }
                .btn-time-current {
                    font-size: $fs-1;
                    padding: 8px;
                }
                .dropdown {
                    .heading-100 {
                        font-size: $fs-1 - 1;
                    }
                }
                .bein-ranking-time-items {
                    padding: 8px 20px;
                }
                .bein-rewards-body {
                    padding: 12px;
                    .bein-rewards-details {
                        font-size: 14px;
                    }
                }
            }
            .bein-card-ranking {
                .heading-100 {
                    font-size: $fs-1 - 2;
                }
            }
            .bein-info {
                .price-two {
                    font-size: $fs-1 - 1;
                    line-height: 20px;
                }
                .heading-400 {
                    font-size: $fs-1 + 2;
                    line-height: 20px;
                }
                .content-price {
                    font-size: $fs-1 - 1;
                }
                .text-privateround {
                    font-size: $fs-1 - 2;
                }
                .bein-bic-title {
                    font-size: $fs-1;
                }
            }
        }
    }

    footer {
        .heading-200 {
            font-size: $fs-1 - 1 !important;
        }
    }
}
@media screen and (max-width: 337px) {
    .bein-group-dark {
        .bein-ranking {
            .bein-card-ranking {
                .body-75 {
                    font-size: 11px;
                }
            }
        }
    }
}
