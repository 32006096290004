.bein-modal-table-ranking {
    width: 688px !important;
    max-height: 362px;
    text-align: center;
    top: 20%;
    ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: $neutral-50;
    }
    ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        background-color: $neutral-80;
        border: 1px solid $neutral-50;
        box-shadow: $box-shadow-1;
        border: 1px solid #627286;
        /* Light Mode/ Elevation 4 */

        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        .heading-300 {
            color: $white-2;
        }
    }
    .bein-modal-header {
        background: $neutral-70;
        padding: $pd-2 - 4;
        border-radius: 6px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        max-height: 48px;
        .heading-300 {
            color: $white-1;
        }
    }
    ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: $neutral-50;
    }
    ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    .bein-card-header {
        padding: $pd-0 + 4 0;
    }
    .bein-modal-body {
        position: relative;
        overflow-y: scroll;
    }
    .bein-card-footer {
        padding: $pd-2;
        transition: 0.3s;
    }
    .bein-leaderboard-user {
        cursor: pointer;
        transition: 0.5s;
    }
    .bein-id-user-1 {
        position: absolute;
    }
    .bein-ranking-member {
        height: 50px;
        .bein-ranking-stt {
            font-family: Palanquin Dark;
            width: 36px;
            height: 31px;
            font-size: $fs-2;
            line-height: 28px;
            font-style: normal;
            font-weight: bold;
            background: linear-gradient(180deg, #ffffff 0%, #ffffff 59.77%, #ebebeb 62.7%, #ffffff 112.5%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }
    }
    .bein-ranking-member-me {
        border-radius: 4px;
        padding: 4px 8px 4px 0px;
        transition: 0.3s;
        background: linear-gradient(180deg, #bf7825 -12%, rgba(250, 147, 57, 0) 160%);
        &:hover {
            background: linear-gradient(180deg, #d17d1a -50%, rgba(237, 154, 81, 0) 220%);
        }
    }
    .bein-ranking-member-me-fix {
        background-color: $neutral-60;
        border-radius: 0px 0px 6px 6px;
        padding: 4px 8px 4px 0px;
    }
    .bein-ranking-bg-top {
        position: relative;
        cursor: pointer;
        &:hover {
            background-color: $neutral-70;
            border-radius: 4px;
            .bein-tooltip-address {
                display: block;
            }
            .bein-rectangle {
                display: block;
            }
        }
        .bein-tooltip-address {
            z-index: 1;
            position: absolute;
            padding: 8px;
            background: $neutral-70;
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            bottom: -40px;
            right: 0;
            display: none;
        }
        .bein-rectangle {
            background: #3f4b5b;
            transform: rotate(45deg);
            position: absolute;
            bottom: -9px;
            right: 41%;
            width: 8px;
            height: 8px;
            display: none;
        }
    }
    .bein-view-all-content {
        cursor: pointer;
        background: $neutral-70;
        border: 1px solid $neutral-50;
        border-radius: $border-radius-1 - 2;
        padding: $pd-0;
        margin: auto;
        transition: 0.3s;
        &:hover {
            background-color: $neutral-60;
        }
    }
    .bein-no-ranking {
        overflow: visible;
        position: relative;
        text-align: center;
        padding: 4px 12px 4px 0px;
        height: 50px;
        background: $neutral-60;
        border-radius: 0px 0px 6px 6px;
        cursor: pointer;
        &:hover {
            .bein-tooltip {
                display: block;
            }
            .bein-rectangle {
                display: block;
            }
        }
        .heading-100 {
            color: $neutral-1;
        }
        .bein-tooltip {
            z-index: 1;
            padding: $pd-0;
            position: absolute;
            top: -50px;
            right: 6%;
            background-color: $neutral-70;
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            display: none;
            max-width: 368px;
        }
        .bein-rectangle {
            display: none;
            background: $neutral-70;
            transform: rotate(45deg);
            width: 8px;
            height: 8px;
            position: absolute;
            top: 0px;
            right: 50%;
        }
    }
    .bein-no-data-ranking {
        height: 240px;
        .heading-75 {
            color: $white-1;
        }
    }
    .heading-200 {
        color: $neutral-5;
    }
    .text-view-all {
        color: $neutral-1;
        transition: 0.3s;
    }
    h3 {
        margin-left: $mg-0;
    }
    .body-25 {
        color: $neutral-1;
    }
    a {
        &:hover {
            color: $neutral-10;
        }
    }
    @media screen and (max-width: 380px) {
        .heading-300 {
            font-size: $fs-1 + 2;
        }
    }
}
