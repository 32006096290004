/*color*/
//link
$blue-5:#4EA2FF;
$blue-30:#43B1FF;
$blue-40: #2396ff;
$blue-50: #4ea2ff;

//background
$neutral-90: #20272f;

//Surface
$neutral-70: #3f4b5b;
$neutral-80: #2f3945;

//Outline
$neutral-30: #92a1b4;

$neutral-50: #627286;
$neutral-60: #505e71;

//text
$neutral-1: #f9fafb;
$neutral-5: #eaedf2;
$neutral-10: #d5dce5;
$neutral-20: #b2bdcd;
$neutral-40: #77879c;


$green-1: #47c287;
$green-2: #0ac458;
$green-40:#0AC458;

$red-4: #ed8d97;
$red-5: #e5616e;

$orange-1: #f48f37;
$orange-30: #f1a257;

$yellow-30:#FFC54C;
$yellow-40: #ffbc1f;
$yellow-50: #ECAB14;

$white-1: #ffffff;
$white-2: #f9fafb;

$primary-5:#EADFFE;
$primary-10:#DBCAFD;
$primary-30: #a17af2;
$primary-40: #8b5ce9;
$primary-50:#7843DD;
$primary-80: #4e18a0;

//font-weight
$fw-2: 600;

//font-size
$fs-1: 14px;
$fs-2: 18px;
$fs-3: 24px;
$fs-5: 32px;

//padding
$pd-0: 8px;
$pd-1: 24px;
$pd-2: 16px;
$pd-3: 35px;

/*margin*/
$mg-0: 12px;
$mg-1: 24px;
$mg-2: 18px;

$border-radius-1: 6px;
$border-radius-2: 8px;
$border-radius-3: 24px;

//box shadow
$box-shadow-1: 0px 8px 24px rgba(0, 0, 0, 0.48);

//responsive

$md: 768px;
$sm: 576px;
