.bein-group-ranking-time-content {
    max-width: 320px;
    border: 1px solid $neutral-50;
    background: $neutral-70;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: auto !important;
    flex-wrap: nowrap;
    .bein-ranking-time-items {
        position: relative;
        background: $neutral-80;
        border-radius: 4px 0px 0px 4px;
        color: $white-1;
        padding: 8px 24px;
    }
    .line {
        position: absolute;
        border: 1px solid #505e71;
        height: 32px;
        right: 0;
        top: 25%;
    }
}
@media screen and (max-width: 360px) {
    .bein-group-ranking-time-content {
        .bein-ranking-time-items {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}
