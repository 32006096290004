.bein-modal-award-history {
    width: 916px !important;
    max-height: 440px;
    text-align: center;
    top: 10%;
    ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: $neutral-50;
    }
    ::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 4px;
    }
    ::-webkit-scrollbar:horizontal {
        height: 4px;
    }
    .line {
        border: 1px solid $neutral-50;
    }
    .line-2 {
        border: 1px solid $neutral-70;
    }
    .heading-100 {
        color: $neutral-1;
        margin-bottom: 0;
    }
    .heading-75 {
        color: $neutral-1;
    }
    .bein-ranking-stt {
        font-family: Palanquin Dark;
        width: 36px;
        height: 36px;
        font-size: $fs-2;
        line-height: 28px;
        font-style: normal;
        font-weight: bold;
        background: linear-gradient(180deg, #ffffff 0%, #ffffff 59.77%, #ebebeb 62.7%, #ffffff 112.5%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .bein-no-data-ranking {
        height: 240px;
        .heading-75 {
            color: $white-1;
        }
    }
    .bein-text-bic {
        color: $green-40;
    }
    .bein-text-bgt {
        color: $primary-40;
    }
    .bein-list-time {
        overflow-y: scroll;
        height: 210px;
        text-align: center;
    }
    .dropdown {
        .heading-100 {
            color: $neutral-1;
        }
        &:hover {
            .text-time-selected {
                color: $neutral-10;
            }
        }
    }
    .dropdown-menu {
        border-radius: 4px;
        background-color: $neutral-80;
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.48);
        padding: 8px;
        left: -16px !important;
    }
    .dropdown-item {
        cursor: pointer;
        background-color: $neutral-80;
        padding: 8px;
        &:hover {
            background-color: $neutral-60;
            border-radius: 4px;
        }
    }
    .dropdown-item-active {
        background: $neutral-70;
        border-radius: 4px;
    }
    .btn {
        &:hover {
            background-color: $neutral-70;
            border-radius: 2px;
        }
    }
    .btn:disabled {
        pointer-events: none;
    }
    .bein-current-time-ranking {
        padding: 10px 8px;
        color: $neutral-1;
    }
    .btn-time-current {
        background: $neutral-70;
        border-radius: 24px;
        padding: 8px 32px;
        color: $neutral-5;
        transition: 0.3s;
        &:hover {
            background-color: $neutral-60;
            border-radius: 24px;
        }
    }
    .bein-group-btn-select {
        max-width: 672px;
        margin: auto;
    }
    .btn-rewards-time {
        width: 100%;
        border: none;
        background-color: transparent;
        padding: $pd-0 - 2;
        padding-left: $pd-1;
        text-align: left;
        font-weight: 600;
        color: $neutral-1;
    }
    .btn-rewards-time-active {
        background-color: $primary-10;
        color: $primary-50;
    }
    .btn-next {
        border: none;
        background-color: transparent;
    }
    .btn-back {
        border: none;
        background-color: transparent;
    }
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        background-color: $neutral-80;
        border: 1px solid $neutral-50;
        box-shadow: $box-shadow-1;
        border: 1px solid #627286;
        /* Light Mode/ Elevation 4 */

        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        .heading-300 {
            color: $white-2;
        }
    }
    .bein-group-option-time {
        background-color: $neutral-70;
        border: 1px solid $neutral-50;
    }
    .bein-modal-header {
        background: $neutral-70;
        padding: $pd-2 - 4;
        border-radius: 6px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        max-height: 48px;
        .heading-300 {
            color: $white-1;
        }
    }
    .bein-card-awards {
        border-radius: 4px 4px 6px 6px;
        background-color: $neutral-80;
        border: 1px solid $neutral-60;
        .bein-card-header-awards {
            background-color: $neutral-70;
            padding: $pd-0 + 4;
        }
        .bein-card-body-awards {
            overflow-y: scroll;
            height: 370px;
            padding: $pd-2;
            .bein-content-awards-user {
                cursor: pointer;
                background: $neutral-70;
                border-radius: 6px;
                padding: 8px;
                &:hover {
                    background-color: $neutral-60;
                }
            }
            .bein-content-awards-current-user {
                background: linear-gradient(180deg, #BF7825 -12%, rgba(250, 147, 57, 0) 160%);
                &:hover{
                    background: linear-gradient(180deg, #D17D1A -50%, rgba(237, 154, 81, 0) 220%);
                }
            }
        }
    }
    .bein-no-ranking {
        overflow: visible;
        position: relative;
        text-align: center;
        padding: 4px 12px 4px 0px;
        height: 50px;
        background: $neutral-60;
        border-radius: 0px 0px 6px 6px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background-color: $neutral-50;
            .bein-tooltip {
                display: block;
            }
            .bein-rectangle {
                display: block;
            }
        }
        .heading-100 {
            color: $neutral-1;
        }
        .bein-tooltip {
            z-index: 1;
            padding: $pd-0;
            position: absolute;
            top: -50px;
            right: 6%;
            background-color: $neutral-70;
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            display: none;
            max-width: 368px;
        }
        .bein-rectangle {
            display: none;
            background: $neutral-70;
            transform: rotate(45deg);
            width: 8px;
            height: 8px;
            position: absolute;
            top: 0px;
            right: 50%;
        }
    }
    .bein-modal-content-header {
        @media screen and (max-width: 490px) {
            .heading-200 {
                font-size: $fs-1 - 2;
            }
        }
        @media screen and (max-width: 380px) {
            .heading-200 {
                font-size: $fs-1 - 5;
            }
        }
    }
    .btn-option-awards {
        padding: 8px 24px;
        cursor: pointer;
    }
    .btn-option-awards-active {
        background-color: $neutral-70;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 3px solid $primary-40;
    }
    .bein-group-btn-select {
        background: $neutral-70;
        border-radius: 12px;
        padding: $pd-0 - 4;
        .btn-select-rank {
            width: 100%;
            border: none;
            height: 36px;
            background-color: transparent;
            padding: $pd-0;
            color: $white-1;
        }
        .active {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
        }
    }
    .bein-modal-group-rewards {
        border: 1px solid $neutral-50;
    }
    .bein-show-time-ranking {
        background-color: $neutral-70;
        padding: $pd-0 - 2;
        border-radius: 4px;
        border: 1px solid $neutral-50;
        color: $neutral-1;
        font-weight: 600;
    }
    .bein-modal-body {
        padding: $pd-2;
        .bein-award-group {
            position: relative;
        }
        .bein-medal {
            position: absolute;
            top: -10px;
            right: 19px;
            width: 20px;
            height: 24px;
        }
        .bein-award-top {
            padding: $pd-0 - 4;
            width: 100%;
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.48);
            border-radius: 8px;
            margin-bottom: $mg-0;
            .bein-award-user-content {
                background: $neutral-70;
                border-radius: 8px;
                padding: $pd-0;
            }
            .bein-award-top-name {
                color: $neutral-80;
            }
            .heading-100 {
                color: $neutral-1;
            }
            .body-25 {
                color: $neutral-1;
            }
            .heading-75 {
                color: $neutral-1;
            }
        }
        .bein-award-top-1 {
            background-color: $yellow-40;
        }
        .bein-award-top-2 {
            background-color: $neutral-10;
        }
        .bein-award-top-3 {
            background-color: $orange-30;
        }
    }
    .bein-leaderboard-user {
        cursor: pointer;
        transition: 0.5s;
    }
    .bein-card-footer{
        border-radius: 0px 0px 6px 6px;
    }
    .bein-ranking-member-me {
                border-radius: 4px;
                padding: 4px 8px 4px 0px;
                transition: 0.3s;
                &:hover {
                    background: linear-gradient(180deg, #BF7825 -27%, rgba(250, 147, 57, 0) 139%) !important;
                }
            }
    .bein-ranking-member-me-fix {
        background-color: $neutral-60;
        border-radius: 0px 0px 6px 6px;
        padding: 4px 12px 4px 12px;
        transition: 0.3s;
        &:hover {
            background-color: $neutral-50;
        }
    }
    @media screen and (max-width: 820px) {
        .btn-option-awards {
            padding: 8px;
        }
    }
    @media screen and (max-width: 768px) {
        .bein-modal-body {
            padding: $pd-0;
        }
    }
    @media screen and (max-width: 576px) {
        .bein-modal-body {
            padding: $pd-0;
        }
        .bein-prize-value {
            margin-left: 40px;
        }
    }
    @media screen and (max-width: 480px) {
        .bein-modal-body {
            padding: $pd-2;
        }
    }
    @media screen and (max-width: 420px) {
        .bein-modal-body {
            padding: $pd-2;
        }
        .bein-content-awards-user {
            .heading-100 {
                font-size: $fs-1 - 3;
            }
        }
        .heading-75 {
            font-size: $fs-1 - 4;
        }
        // .bein-prize-value{
        //     margin-left: 0;
        // }
        .btn-time-current {
            padding: 8px 16px;
        }
    }
    @media screen and (max-width: 380px) {
        .bein-modal-body {
            padding: $pd-0;
        }

        .btn-option-awards {
            font-size: $fs-1 - 2;
        }
        .bein-card-body-awards {
            padding: $pd-0 !important;
        }
        .col-7 {
            max-width: 120px;
        }
        .btn-time-current {
            padding: 8px;
            font-size: $fs-1;
        }
        .dropdown {
            .heading-100 {
                font-size: $fs-1 - 2;
            }
        }
    }
}
