body {
    background-color: $neutral-90;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
        "Helvetica Neue", sans-serif;
}
html {
    scroll-behavior: smooth;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.margin-y-24 {
    margin: $mg-1 0;
}
.container {
    padding: $pd-1;
}
.body-75 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $neutral-40;
}
.body-25 {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: $neutral-1;
}
.heading-25 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: 9px;
    line-height: 16px;
    color: $neutral-5;
}
.heading-50 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: 11px;
    line-height: 16px;
    color: $neutral-20;
}
.heading-75 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: $fs-1 - 2;
    line-height: 16px;
    color: $neutral-20;
}
.heading-100 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: $fs-1;
    line-height: 20px;
    color: $white-1;
    margin-bottom: 0;
}
.heading-200 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: $fs-2 - 2;
    line-height: 24px;
    color: $neutral-20;
}
.heading-300 {
    font-weight: $fw-2;
    font-size: $fs-2;
    line-height: 24px;
}
.heading-400 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: $fs-2 + 2;
    line-height: 28px;
    color: $orange-1;
}
.heading-600 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: $fs-3;
    line-height: 32px;
    color: #f9fafb;
}
.heading-800 {
    font-style: normal;
    font-weight: $fw-2;
    font-size: 28px;
    line-height: 32px;
    color: $orange-1;
}
.content-note {
    font-style: italic;
    font-weight: bold;
    font-size: $fs-2 - 4;
    line-height: 20px;
    color: $neutral-1;
}
.text-success {
    color: $green-1;
}
.text-success-bein {
    color: $green-2;
}
.text-error-bein {
    color: $red-5;
    font-weight: $fw-2;
    line-height: 16px;
}
.text-error {
    color: $red-5;
    font-weight: $fw-2;
    font-size: $fs-1 - 2;
    line-height: 16px;
}
.input-error {
    border: 1px solid $red-5 !important;
}
.input-price-error {
    border: 1px solid $red-5 !important;
}
.text-modal-success {
    color: $primary-30 !important;
}
.text-modal-success-content {
    color: $neutral-5 !important;
}
.bein-text-bgt {
    color: #bc9ff8;
}
.bein-text-bic {
    color: #47c287;
}
.bein-text-bir {
    color: #47c287;
}
.bein-text-busd {
    color: #f48f37;
}
.text-neutral-1 {
    color: $neutral-1 !important;
}
.text-neutral-5 {
    color: $neutral-5 !important;
}
.text-neutral-70 {
    color: $neutral-70 !important;
}
.text-neutral-80 {
    color: $neutral-80 !important;
}
.text-primary-30 {
    color: $primary-30 !important;
}
.text-primary-40 {
    color: $primary-40 !important;
}
.text-primary-50 {
    color: $primary-50 !important;
}
.text-primary-80 {
    color: $primary-80 !important;
}
.text-green-40 {
    color: $green-40 !important;
}
.text-blue-50 {
    color: $blue-50 !important;
}
.text-red-5 {
    color: $red-5 !important;
}
.bg-disabled {
    background-color: $neutral-90 !important;
    opacity: 0.4;
}
.link-color {
    color: $blue-40 !important;
    &:hover {
        color: $blue-30 !important;
    }
}
.bein-group {
    h3 {
        margin: 0;
    }
    p {
        margin: 0;
    }
}
.bg-icon {
    background-color: $neutral-90;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    margin-right: 4px;
}
.dropdown-menu {
    left: 0;
    bottom: 0;
    background-color: transparent;
}
.dropdown-menu > a {
    background-color: #2f3945;
    cursor: pointer;
    padding: 8px;
    &:hover {
        background-color: $neutral-60;
        border-radius: 4px;
    }
    color: $neutral-5;
}
.bein-group-dark {
    max-width: 960px;
    margin: auto;

    .header {
        border: 1px solid #627286;
        margin-top: 0;
        background-color: $neutral-80;
        border-radius: $border-radius-2;
        .header-img {
            background-size: cover !important;
            height: 144px;
            border-radius: $border-radius-1;
            background-position: center !important;
        }
        .header-introduce {
            padding: $pd-1;
            border-radius: 0 0 $border-radius-2 $border-radius-2;
            h3 {
                font-size: $fs-5;
                line-height: 40px;
                font-weight: $fw-2;
                color: $neutral-5;
            }
            a {
                padding: 8px 20.5px;
                border-radius: $border-radius-3;
                background: $neutral-70;
                transition: 0.3s;
                span {
                    color: $blue-50;
                }
                &:hover {
                    background-color: $neutral-60;
                }
            }
        }
    }
    .header-notification {
        padding: $pd-0 $pd-1;
        background: $neutral-80;
        border-radius: $border-radius-2;
        .heading-100 {
            color: $white-1;
            font-style: normal;
            margin-left: $mg-0 - 4;
        }
    }
    .bein-card {
        height: 100%;
        background-color: $neutral-80;
        border: 1px solid $neutral-50;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.48);
        border-radius: $border-radius-2;
        .bein-card-header {
            background-color: $neutral-70;
            border-radius: $border-radius-2 $border-radius-2 0 0;
            padding: $pd-2;
            h3 {
                color: $neutral-5;
                font-size: $fs-2;
                font-weight: $fw-2;
                line-height: 24px;
            }
        }
        .bein-card-body {
            padding: $pd-2;
            h4 {
                margin: $mg-1 0 $mg-0 0;
                color: $neutral-1;
            }
            h3 {
                margin-bottom: $mg-0 - 4;
            }
        }
        .bein-card {
            position: relative;
        }
        .bein-card-footer {
            padding: $pd-2;
            transition: 0.3s;
        }
        .divider {
            margin-top: $mg-1;
            margin-bottom: $mg-1 + 2;
            border: 0.5px solid $neutral-60;
        }
        .price-two {
            //color: $orange-1;
            color: $green-1;
            line-height: 28px;
        }
        .text-privateround {
            font-size: $fs-1;
        }
        .text-privateround-mac {
            font-size: $fs-1 - 1;
        }
        .price-privateround {
            color: $primary-40;
        }
    }
    .bein-card-right {
        .text-title {
            color: $blue-5;
            font-size: $fs-1;
            line-height: 20px;
            font-style: italic;
            &:hover {
                color: $blue-30;
            }
        }
        .content-balance {
            .bein-balance-content-rewards {
                width: 100%;
                height: 68px;
                background-color: #fbc55f;
                border-radius: 4px;
                cursor: pointer;
                height: 100%;
                transition: 0.3s;

                &:hover {
                    background-color: #ffd481;
                }
                .bein-balance-img-medal {
                    width: 24px;
                    height: 28px;
                }
                .content-balance-rewards {
                    color: $neutral-90;
                }
            }
        }
        .bein-price {
            border: 1px solid $neutral-30;
            border-radius: $border-radius-1 - 2;
            margin-top: $mg-0 + 4;
            padding: $pd-0;
            .content-balance-name {
                color: $neutral-30;
            }
            .content-balance-price {
                color: $neutral-5;
            }
        }

        .content-input-price {
            background-color: $neutral-80;
            width: 100%;
            border: 1px solid $neutral-60;
            border-radius: $border-radius-1 - 2;
            padding: $pd-0 + 4;
            .address-input-whitelist {
                width: 100%;
                outline: none;
                border: none;
                background-color: inherit;
                padding: 0;
            }
        }
    }
    .bein-form {
        margin-bottom: 0;
        .form-price-top {
            .input-wrap {
                border-radius: $border-radius-1 - 2;
                width: 100%;
                border: 1px solid $neutral-60;
                background: $neutral-80;
                padding-right: $pd-0 + 4;
            }
            .input-price {
                padding: $pd-0 + 4;
                background: transparent;
                border: none;
                width: 100%;
                outline: none;
                color: $neutral-5;
                &::placeholder {
                    color: $neutral-40;
                }
                &:disabled {
                    border-color: inherit;
                }
            }
            .btn-max-busd {
                white-space: nowrap;
                width: fit-content;
                background-color: inherit;
                color: $primary-40;
                border: none;
                font-weight: bold;
                padding: 0;
                transition: 0.3s;
                &:hover {
                    color: $primary-30;
                }
            }
        }
        .heading-200 {
            color: $neutral-5;
            margin-bottom: $mg-0 - 4;
        }
        .form-price-bot {
            margin: $mg-2 0;
            .heading-200 {
                color: $neutral-20;
                font-weight: normal;
            }
            .input-price {
                background-color: inherit;
                outline: none;
                border: none;
                color: $neutral-1;
                &:disabled {
                    border-color: inherit;
                }
            }
            .line {
                border: 1px solid $neutral-70;
                margin: 0px $mg-0 + 4;
            }
        }
        .heading-100 {
            font-weight: normal;
            color: $neutral-20;
        }
        .bein-card-button {
            .form-button {
                width: 480px;
                margin: auto;
                margin-top: $mg-1;
                margin-bottom: $mg-0 - 4;
            }
            .heading-200 {
                color: $neutral-20;
            }
            .heading-75 {
                color: $red-4;
            }
        }
        .form-floating-button {
            position: fixed;
            bottom: 40px;
            right: 60px;
            height: 52px;
            width: 52px;
            background-color: $neutral-5;
            border: 1px solid $neutral-50;
            box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.48);
            border-radius: 40px;
            padding: $pd-0;
            cursor: pointer;
            z-index: 10;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-1;
            }
        }
        .form-floating-button-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .floating-button-tooltip {
                visibility: hidden;
                width: 165px;
                background-color: $neutral-70;
                color: #fff;
                text-align: center;
                border-radius: $border-radius-1;
                padding: $pd-0;

                /* Position the tooltip */
                position: absolute;
                z-index: 1;
                top: -180%;
                left: 50%;
                margin-left: -90px;
            }
            &:hover {
                .floating-button-tooltip {
                    visibility: visible;
                }
                .floating-button-rectangle {
                    visibility: visible;
                }
            }
            .floating-button-rectangle {
                visibility: hidden;
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: $neutral-70;
                transform: rotate(45deg);
                top: -65%;
            }
        }
    }
    .bein-contract {
        margin-top: $mg-1;
        .infomation-wallet {
            color: $blue-5;
            font-weight: 600;
            font-style: italic;
            margin-left: 36px;
            &:hover {
                color: $blue-30;
            }
        }
    }
    .bein-card-chart {
        canvas {
            height: 100% !important;
            padding: $pd-1 - 8;
            padding-top: 0;
            color: $white-1;
        }
    }
    .bein-ranking {
        .bein-card-ranking {
            padding: $pd-0;
            background: $neutral-80;
            border: 0.5px solid $neutral-40;
            border-radius: $border-radius-2;
            .bein-content-personal-ranking {
                transition: 0.3s;
                cursor: pointer;
                &:hover {
                    background-color: $neutral-70;
                    border-radius: 4px;
                }
                .heading-75 {
                    font-size: 10px;
                }
            }
            .bein-ranking-content {
                display: flex;
                height: 46px;
                flex-direction: column;
                justify-content: space-between;
            }
            .heading-100 {
                color: $white-1;
            }
            .line {
                border: 1px solid $neutral-40;
            }
            .text-error-bein {
                color: $red-4;
            }
        }
        .body-75 {
            color: $neutral-20;
        }
        .btn-contribute-ranking {
            background-color: $neutral-70;
            border: 1px solid $neutral-50;
            border-radius: 4px;
            padding: $pd-0;
            color: $neutral-5;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-60;
            }
        }
        .bein-user-not-contribute {
            background: url("../../assets/img/BlockRank.png");
            background-position: center;
            background-size: cover;
            border: 1px solid $neutral-50;
            border-radius: 8px;
            padding: 19px 12px;
            height: 100%;
        }
    }
    .bein-card-rewards {
        ::-webkit-scrollbar {
            width: 4px;
            height: 8px;
            background-color: $neutral-50;
        }
        ::-webkit-scrollbar-thumb {
            background: #c4c4c4;
            border-radius: 4px;
        }
        ::-webkit-scrollbar:horizontal {
            height: 4px;
        }
        .bein-list-time {
            overflow-y: scroll;
            height: 210px;
            text-align: center;
        }
        .dropdown {
            .heading-100 {
                color: $neutral-1;
            }
            &:hover {
                .text-time-selected {
                    color: $neutral-10;
                }
            }
        }
        .dropdown-menu {
            border-radius: 4px;
            background-color: $neutral-80;
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.48);
            padding: 8px;
            left: -16px !important;
        }
        .dropdown-item {
            cursor: pointer;
            background-color: $neutral-80;
            padding: 8px;
            &:hover {
                background-color: $neutral-60;
                border-radius: 4px;
            }
        }
        .dropdown-item-active {
            background: $neutral-70;
            border-radius: 4px;
        }
        .btn {
            outline: none;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                background-color: $neutral-60;
                border-radius: 2px;
            }
        }
        .btn:disabled {
            pointer-events: none;
        }
        .bein-current-time-ranking {
            padding: 10px 8px;
            color: $neutral-1;
        }
        .bein-link-detail {
            color: $blue-5;
            font-style: italic;
            &:hover {
                color: $blue-40;
            }
        }
        .btn-time-current {
            background: $neutral-70;
            border-radius: 24px;
            padding: 8px 32px;
            color: $neutral-5;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-60;
                border-radius: 24px;
            }
        }
        .bein-group-btn-select {
            background: $neutral-70;
            border-radius: 12px;
            padding: $pd-0 - 4;
            max-width: 672px;
            margin: auto;
        }
        .btn-select-rank {
            border: none;
            width: 100%;
            height: 36px;
            background-color: transparent;
            transition: 0.3s;
            color: $white-1;
            &:hover {
                background: rgba(255, 255, 255, 0.05);
                border-radius: 8px;
            }
        }
        .active {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            pointer-events: none;
        }
        .bein-group-ranking-time {
            max-width: 672px;
            margin: $mg-1 auto;
            .bein-gif-time {
                border-radius: 50px;
                width: 48px;
                height: 48px;
            }
            .heading-100 {
                color: $white-1;
                font-size: $fs-1;
            }
            .heading-200 {
                color: $white-1;
            }
            .heading-400 {
                color: $neutral-50;
                margin: 0 2px;
            }
            .body-75 {
                color: $neutral-30;
            }
        }
        ::-webkit-scrollbar {
            width: 4px;
            height: 8px;
            background-color: $neutral-50;
        }
        ::-webkit-scrollbar-thumb {
            background: #c4c4c4;
            border-radius: 4px;
        }
        ::-webkit-scrollbar:horizontal {
            height: 4px;
        }
        .bein-card-header {
            padding: $pd-0 + 4 0;
        }
        .bein-card-body {
            position: relative;
            overflow-y: scroll;
        }
        .bein-leaderboard-user {
            cursor: pointer;
            transition: 0.5s;
        }
        .bein-id-user-1 {
            position: absolute;
        }
        .bein-ranking-member {
            height: 50px;
            .bein-ranking-stt {
                font-family: Palanquin Dark;
                width: 36px;
                height: 31px;
                font-size: $fs-2;
                line-height: 28px;
                font-style: normal;
                font-weight: bold;
                background: linear-gradient(180deg, #ffffff 0%, #ffffff 59.77%, #ebebeb 62.7%, #ffffff 112.5%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }
        }
        .bein-ranking-member-me {
            border-radius: 4px;
            padding: 4px 8px 4px 0px;
            transition: 0.3s;
            background: linear-gradient(180deg, #bf7825 -12%, rgba(250, 147, 57, 0) 160%);
            &:hover {
                background: linear-gradient(180deg, #d17d1a -50%, rgba(237, 154, 81, 0) 220%);
            }
        }
        .bein-ranking-member-me-fix {
            background-color: $neutral-60;
            border-radius: 0px 0px 6px 6px;
            padding: 4px 12px 4px 12px;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-50;
            }
        }
        .bein-ranking-bg-top {
            position: relative;
            cursor: pointer;
            &:hover {
                background-color: $neutral-70;
                border-radius: 4px;
                .bein-tooltip-address {
                    display: block;
                }
                .bein-rectangle {
                    display: block;
                }
            }
            .bein-tooltip-address {
                z-index: 1;
                position: absolute;
                padding: 8px;
                background: $neutral-70;
                box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
                border-radius: 6px;
                bottom: -40px;
                right: 0;
                display: none;
            }
            .bein-rectangle {
                background: #3f4b5b;
                transform: rotate(45deg);
                position: absolute;
                bottom: -9px;
                right: 41%;
                width: 8px;
                height: 8px;
                display: none;
            }
        }
        .bein-view-all-content {
            cursor: pointer;
            background: $neutral-70;
            border: 1px solid $neutral-50;
            border-radius: $border-radius-1 - 2;
            padding: $pd-0;
            margin: auto;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-60;
            }
        }
        .bein-no-ranking {
            overflow: visible;
            position: relative;
            text-align: center;
            padding: 4px 12px 4px 0px;
            height: 50px;
            background: $neutral-60;
            border-radius: 0px 0px 6px 6px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                background-color: $neutral-50;
                .bein-tooltip {
                    display: block;
                }
                .bein-rectangle {
                    display: block;
                }
            }
            .heading-100 {
                color: $neutral-1;
            }
            .bein-tooltip {
                z-index: 1;
                padding: $pd-0;
                position: absolute;
                top: -50px;
                right: 6%;
                background-color: $neutral-70;
                box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
                border-radius: 6px;
                display: none;
                max-width: 368px;
            }
            .bein-rectangle {
                display: none;
                background: $neutral-70;
                transform: rotate(45deg);
                width: 8px;
                height: 8px;
                position: absolute;
                top: 0px;
                right: 50%;
            }
        }
        .bein-no-data-ranking {
            height: 240px;
            .heading-75 {
                color: $white-1;
            }
        }
        .heading-200 {
            color: $neutral-5;
        }
        .text-view-all {
            color: $neutral-1;
            transition: 0.3s;
        }
        h3 {
            margin-left: $mg-0;
        }
        .body-25 {
            color: $neutral-1;
        }
        a {
            &:hover {
                color: $neutral-10;
            }
        }
    }
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px #9880ff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #9880ff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #9880ff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}
footer {
    margin: $pd-1 0;
    margin-bottom: 0;
    .heading-200 {
        color: #77879c !important;
    }
}
@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 9984px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #9880ff;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 9999px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #9880ff;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 10014px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #9880ff;
    }
}
